.logo{

  
  color: #3fb0ac;

  img{
    width: 40px;
  }

  font-size: 30px;
  font-family: 'PT Serif', serif;

  &__small{
    img{
      width: 30px;
    }
    font-size: 20px;
  }
}